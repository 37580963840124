.categories-page{
    text-align: center;
}

.subCategories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.brand-image {
  max-width: 100px;
  max-height: 70px;
}
  
.grayed-out {
    opacity: 0.3; /* Adjust the opacity or apply other styles to gray out the image */
}

.subCategory{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px; /* Adjust the width and height as needed */
    max-height: 120px;
    border-radius: 50%;
    background-color: rgb(255, 255, 133);
    color: black;
    text-align: center;
    font-size: 14px; /* Adjust font size */
    padding: 20px; /* Adjust padding */
    }

.subCategories:hover{
    cursor: pointer;
}

.subCategory-image {
    height: 200px;
    width: 200px;
}

@media (max-width: 1000px) {
    .subCategory{
      width: 50px; /* Hide categories on small screens */
      max-height: 50px;
    }
    .subCategory img {
        width: 120px;
        height: 120px;
    }
}