.header {
    height: 50px;
    background-color: rgb(255, 255, 133);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px black;
    gap: 10px;
  }
  
  .header-left, .header-right {
    display: flex;
    align-items: center;
  }
  
  .header-middle {
    flex-grow: 1;
  }
  
  .header-logo:hover,
  .header-search-image:hover,
  .header-cart-image:hover,
  .header-menu:hover,
  .header-menu-close:hover {
    cursor: pointer;
  }

  .header-menu {
    max-height: 30px;
    display: none;
    padding-left: 10px;
  }

  .header-menu-close {
    max-height: 30px;
  }

  .header-logo{
    display: flex;
    align-items: center;
  }
  
  .header-logo-image {
    height: 50px;
  }
  
  .header-title {
    padding-right: 10px;
  }
  
  .header-categories {
    display: flex;
    gap: 20px;
    font-size: x-large;
    margin-bottom: -3px;
  }

  .header-categories :hover,
  .header-links a:hover,
  .header-menu-close:hover,
  .overlay-links a:hover {
    opacity: 0.7;
  }

  .header-search {
    display: flex;
    align-items: center;
  }
  
  .header-search-bar {
    width: 100%; /* Use 100% width for responsiveness */
    border-radius: 10px;
    border-color: rgb(0, 0, 0);
  }
  
  .header-search-image {
    height: 20px;
    padding-left: 5px;
  }

  .header-links {
    display: flex;
    align-items: center;
  }
  
  .header-cart {
    text-align: center;
    margin-right: 10px;
  }
  
  .header-cart-image {
    height: 25px;
    margin-top: 2px;
    margin-bottom: -7px;
  }
  
  a {
    text-decoration: none;
    color: black;
  }
  
  .header {
  height: 50px;
  background-color: rgb(255, 255, 133);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 2px black;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
}

.header-middle {
  flex-grow: 1;
}

.header-logo:hover,
.header-search-image:hover,
.header-cart-image:hover,
.overlay-links a:hover {
  cursor: pointer;
}

.header-logo-image {
  height: 50px;
}

.header-title {
  padding-right: 10px;
}

.header-categories {
  display: flex;
  gap: 20px;
  font-size: x-large;
}

.header-search-bar {
  width: 100%; /* Use 100% width for responsiveness */
  margin-left: 5px;
}

.header-search-image {
  height: 20px;
  padding-left: 5px;
}

.header-cart {
  text-align: center;
  margin-right: 10px;
}

.header-cart-image {
  height: 25px;
  margin-top: 2px;
  margin-bottom: -7px;
}

a {
  text-decoration: none;
  color: black;
}

.overlay-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 133);
    padding: 5px 10px 5px 10px;
    margin-bottom: -10px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900;
  }  
  
  .overlay.show {
    display: block;
  }
  
  .overlay-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000; /* Ensure the modal is above other elements */
  }
  .overlay-links {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .overlay-links a {
    color: black;
    text-decoration: none;
    font-size: x-large;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .overlay-divider {
    background-color: black;
    height: 1px;
    margin-bottom: 10px;
  }

@media (max-width: 1000px) {
    .header-categories,
    .header-title {
      display: none; /* Hide categories on small screens */
    }

    .header-menu {
        display: flex;
    }
  }
  