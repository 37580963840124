* {
    font-family: 'Caveat', cursive;
}

body {
    margin: 0px;
}

.home {
    text-align: center;
}

.motto {
    position: relative;
}

/* .motto-image {
    max-width: 100%;
    border-bottom: solid 3px black;
}

.motto-text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 80px;
    width: 100%;
} */

.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}