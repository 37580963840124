.product {
    text-align: center;
    border: solid 1px rgb(210, 210, 210);
    width: 275px;
    color: rgb(37, 36, 36);
}

.product-image {
    padding-top: 10px;
    height: 275px;
    max-width: 275px;
}

.product-name{
    height: 75px;
    padding-left: 10px;
    padding-right: 10px;
}

.product-cost {
    margin-top: -10px;
}

.product-add {
    margin-bottom: 10px;
}

.admin-product-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

@media (max-width: 1000px) {
    .product{
      width: 165px;
      font-size: small;
    }

    .product-image{
        max-width: 165px;
        max-height: 165px;
    }
}