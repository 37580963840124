/* CSS for the modal container */
.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the modal is above other elements */

    /* Allows for content to display correctly */
    display: flex;
    justify-content: center;
  }
  
  /* Optionally, add styles for an overlay to dim the background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900; /* Ensure the overlay is below the modal */
  }  

  
.product-form {
    display: inline-block;
}

.product-form-section{
    padding: 5px;
    display: flex;
    width: 250px;
    margin: 0px 10px 10px 0px;
}

.product-form-name {
    flex: 50%;
    text-align: left;
}

.product-form-value {
    width: 100%
}

.form-response {
    color: green;
    margin-top: 10px;
}

.product-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-buttons {
    margin-bottom: 10px;
}

.product-dropdown {
    width: 100%;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 178px;
    z-index: 1;
    background-color: rgb(255, 255, 255);
}

.dropdown:hover .dropdown-content{
    display: block;
    cursor: pointer;
}

.dropdown:hover .dropdown-content .dropdown-main-category:hover{
    background-color: rgb(215, 215, 215);
}

.dropdown:hover .dropdown-content div{
    border: solid 1px rgb(193, 193, 193);
    display: block;
}