.admin{
    text-align: center;
}

.product-stats{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.product-stats div{
    border: solid 1px rgb(220, 220, 220);
    width: 200px;
}

.add-product {
    display: none;
    border-top: solid 1px rgb(220, 220, 220) ;
    border-bottom: solid 1px rgb(220, 220, 220);
    padding-bottom: 20px;
    margin-top: 20px;
}

.admin-search { 
    margin-top: -25px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.search-bar{
    width: 400px;
    margin-left: 5px;
}

.search-image{
    height: 20px;
    padding-left: 5px;
}

.search-image:hover,
.product-stats-box:hover{
    cursor: pointer
}